import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import LocalizarPessoas from "../components/LocalizarPessoas";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";

const LocalizarPessoa = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="Localização de pessoas" />
        <LocalizarPessoas />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default LocalizarPessoa;
