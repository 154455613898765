import React from "react";
import ReactDOM, { hydrate, render } from "react-dom";
import "font-awesome/css/font-awesome.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-W766RNK'
}

TagManager.initialize(tagManagerArgs)


// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
