import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

const Select = ({ name, label, ...rest }) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: selectRef.current,
    })
  }, [fieldName, registerField])

  return (
    <>
      <select
        id={fieldName}
        ref={selectRef}
        defaultValue={defaultValue}
        className={error ? 'has-error form-control' : 'form-control'}
        {...rest}
      >
        <option value="0">Horário para contato</option>
        <option value="Entre 09h as 10h">Entre 09h as 10h </option>
        <option value="Entre 10h as 11h">Entre 10h as 11h </option>
        <option value="Entre 11h as 12h">Entre 11h as 12h </option>
        <option value="Entre 12h as 13h">Entre 12h as 13h </option>
        <option value="Entre 13h as 14h">Entre 13h as 14h </option>
        <option value="Entre 14h as 15h">Entre 14h as 15h </option>
        <option value="Entre 15h as 16h">Entre 15h as 16h </option>
        <option value="Entre 16h as 17h">Entre 16h as 17h </option>
        <option value="Entre 17h as 18h">Entre 17h as 18h </option>
      </select>

      {error && <span className="NameError">{error}</span>}
    </>
  )
}
export default Select;