import React , {useEffect, useRef} from 'react';
import {useField} from '@unform/core';

const Input = ({name, label, ...rest}) => {
  const inputRef = useRef(null)
  const {fieldName, defaultValue, registerField, error} = useField(name)

  useEffect(()=>{
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  },[fieldName, registerField])

  return (
    <div>
      <input
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        className={error ? 'form-control is-invalid': 'form-control'}
        {...rest}
        required
      />
  
      {error && <span className="invalid-feedback" style={{marginTop: "-30px", marginBottom:"10px"}}>{error}</span>}
    </div>
  )
}
export default Input;