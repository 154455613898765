import { BrowserRouter as Router, Route, Switch, HashRouter } from "react-router-dom";

import Home from "./pages/Home";
import SelectUf from "./pages/SelectUf";
import About from "./pages/About";
import Investigation from "./pages/Investigation";
import RedeSocial from "./pages/RedeSocial";
import LocalizarPessoas from "./pages/LocalizarPessoas";
import LocalizarDevedores from "./pages/LocalizarDevedores";
import LocalizarGolpista from "./pages/LocalizarGolpista";
import GolpistaAmoroso from "./pages/GolpistaAmoroso";
import Levantamento from "./pages/Levantamento";
import Rastreamento from "./pages/Rastreamento";
import Contact from "./pages/Contact";
import Pericia from "./pages/Pericia";
import Dossie from "./pages/Dossie";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <HashRouter hashType="noslash">
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/sobre"}`}
          component={About}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/rede_sociais"}`}
          component={RedeSocial}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/localizar_pessoas"}`}
          component={LocalizarPessoas}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/localizar_devedores"}`}
          component={LocalizarDevedores}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/localizar_golpista"}`}
          component={LocalizarGolpista}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/levantamento"}`}
          component={Levantamento}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/golpista_amoroso"}`}
          component={GolpistaAmoroso}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/politica_privacidade"}`}
          component={PrivacyPolicy}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/rastreamento"}`}
          component={Rastreamento}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/pericia"}`}
          component={Pericia}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/dossie"}`}
          component={Dossie}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/investigacao"}`}
          component={Investigation}
        />
        <Route
          path={`${process.env.PUBLIC_URL + "/:uf/Contato"}`}
          component={Contact}
        />
        <Route
          exact
          path="/"
          component={SelectUf}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/:uf"}`}
          component={Home}
        />
      </Switch>
    </HashRouter>
  );
}

export default App;
