import React from "react";


import Mainslider from "../components/Mainslider";
import About from "../components/About";
import Counter from "../components/Counter";
import Services from "../components/Services";
import Testimonial from "../components/Testimonial";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";
import MenuBody from "../components/MenuBody";
import { useParams } from "react-router";

import CookieConsent from "react-cookie-consent";

import WhatsAppBanner from "../components/WhatsAppBanner";

const Home = () => {

  const { uf } = useParams()

  return (
    <>
      <Header />
      <Header />
      <main>
        <Mainslider />
        <MenuBody />
        <About />
        {/* <Counter /> */}
        <Services />
        <Testimonial />
        <WhatsAppBanner uf={uf} />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
      {
        uf.toUpperCase() === 'MARCOS'
          ? <CookieConsent
            location="bottom"
            buttonText="Aceito"
            expires={150}
          >Este site usa cookies para melhorar a experiência do usuário </CookieConsent>
          : null
      }

    </>
  );
};

export default Home;
