import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import ContactContent from "../components/ContactContent";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";

const Contact = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="contato" />
        <ContactContent />
        <Footer addressShow={true}/>
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default Contact;
