"use client"

import { useState, useEffect } from "react";
import Unidades from "../context/Unidades";

export default function WhatsAppBanner({ uf }) {

    const [locale, setLocale] = useState([])
    const [address, setAddress] = useState([])


    useEffect(() => {
        setLocale(Unidades.renderRegiao(uf))
        setAddress(Unidades.renderAddress(uf))
        console.log(locale);
    }, [])

    return (
        <>
            <div className="whats-banner-div">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-6 col-md-9 offset-md-3 col-12">
                            <h3 className="whats-banner-title">Detetive Marcos</h3>
                            <p className="whats-banner-paragraph">O Especialista Virtual Marcos oferece serviços discretos e eficazes para resolver seus problemas.</p>
                            <p className="whats-banner-paragraph">Contamos com tecnologia avançada para coletar e analisar informações relevantes de forma precisa.</p>
                            <p className="whats-banner-paragraph">Para garantir sua privacidade e conveniência, agendamos consultas conforme sua disponibilidade.</p>
                            <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send/?phone=${locale.length > 0 ? locale[0].contato : null}`} target="_blank" className="btn-type-3">
                                Agende agora
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}