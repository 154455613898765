import React from "react";

const FooterSubject = () => {
  return (
    <>
        <br />
        Surpreenda-se com as informações e provas concretas que podemos encontrar para você.
        <br />
        <br />
        Entre em contato com a empresa Detetive Marcos e tenha o seu problema resolvido!
        <br />
        <br />
        <strong>“SEU PROBLEMA, MEU PROBLEMA”.</strong>

        <div style={{
            "font-size":"small",
            "font-size": "xx-small",
            "white-space": "break-spaces",
            "line-height": "initial",
            "padding": "30px",
            "text-align": "justify"}}>
          <div>
              Todos os nossos serviços, quais sejam: 
            Especialista em redes sociais,  Encontrar Pessoas, 
            Intermediação com Devedores, Intermediação com Golpista, 
            Levantamento de Informações, 
            Bens Desviados, Análise  em Computadores e 
            Celulares e Dossiê Completo Pessoa/Empresa, 
            são realizados respeitando todas as normas vigentes 
            assimilado ao nosso know how.
          </div>
 
          <div>
              Além disso, frisamos que as todas as informações são obtidas 
            por meios lícitos em banco de dados públicos, 
            redes sociais ,respeitando a legislação de proteção de dados (LGPD),
            além do direito constitucional da intimidade e da vida privada.

          </div>
          <div>
              Ademais, os serviços prestados por nossa empresa só acontecem 
            após a procura e autorização do próprio contratante, 
            mediante assinatura do contrato de prestação de serviços,
            permitindo que os trabalhos por ele pactuados sejam realizados.
          </div>          
        </div>
    </>
  );}

  export default FooterSubject;