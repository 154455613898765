import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import RastreamentoBens from "../components/RastreamentoBens";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";

const Rastreamento = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="Bens desviados" />
        <RastreamentoBens />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default Rastreamento;
