import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams, withRouter } from "react-router";

const PoliticaPrivacidade = () => {

    const { uf } = useParams();

    return (
        <>
            <div className="pt-50">
                <div className="container">

                    <div className="row">
                        <div classname="col-xl-12">
                            <div className="section-title mx">
                                <h3>Política de Privacidade</h3>
                                <p className="">O Marcos Especialista Virtual está comprometido em proteger a privacidade dos visitantes de nosso site. Esta Política de Privacidade descreve como coletamos, usamos e protegemos as informações pessoais que você fornece através deste site.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title">
                                <h2>Informações Coletadas</h2>
                                <p>Podemos coletar informações pessoais identificáveis, como nomes, endereços de e-mail, números de telefone e outras informações que você voluntariamente nos fornece ao preencher formulários em nosso site, fazer pedidos de serviços ou entrar em contato conosco por e-mail ou outros meios.</p>
                                <p>Também podemos coletar automaticamente determinadas informações sobre o dispositivo que você está usando para acessar nosso site, incluindo seu endereço IP, tipo de navegador, sistema operacional, páginas visitadas e outras informações de uso.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title">
                                <h2>Uso das Informações</h2>
                                <p>As informações que coletamos podem ser usadas das seguintes maneiras:</p>
                                <ul className="privacy-list">
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para fornecer serviços solicitados por você.
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para melhorar nosso site e serviços com base no feedback que recebemos de você.
                                    </li>
                                    <li>
                                        <i className="fa fa-check"></i>
                                        Para proteger nossos direitos legais e cumprir com obrigações legais.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section-title">
                                <h2>Compartilhamento de Informações</h2>
                                <p>Não vendemos, comercializamos ou transferimos suas informações pessoais para terceiros sem o seu consentimento, exceto quando necessário para fornecer os serviços solicitados por você, cumprir com obrigações legais ou proteger nossos direitos legais.</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>Proteção de Informações</h2>
                                    <p>Implementamos medidas de segurança físicas, eletrônicas e administrativas para proteger as informações pessoais que coletamos contra acesso não autorizado, alteração, divulgação ou destruição.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>Cookies</h2>
                                    <p>Nosso site pode usar cookies para melhorar a experiência do usuário. Os cookies são arquivos de texto armazenados em seu dispositivo que ajudam a analisar o tráfego da web ou permitem que você seja reconhecido quando visita nosso site novamente. Você pode optar por recusar o uso de cookies ajustando as configurações do seu navegador, mas isso pode afetar a funcionalidade do site.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>Menores de Idade</h2>
                                    <p>Nosso site não se destina a menores de 18 anos, e não coletamos intencionalmente informações pessoais de menores. Se você é menor de idade, não deve fornecer informações pessoais em nosso site sem o consentimento de um responsável legal.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>Alterações nesta Política de Privacidade</h2>
                                    <p>Reservamos o direito de atualizar ou modificar esta Política de Privacidade a qualquer momento, por isso recomendamos que você revise esta página periodicamente para ficar atualizado sobre quaisquer alterações. A data da última atualização será indicada na parte inferior desta página.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h2>Contato</h2>
                                    <p>Se você tiver dúvidas sobre esta Política de Privacidade ou sobre o uso de suas informações pessoais, entre em contato conosco através dessa
                                        <Link to={`${process.env.PUBLIC_URL + "/" + uf + "/contato"}`}> página.</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-12 offset-xl-1 wow fadeInRight">
                                <div className="section-title">
                                    <p>
                                        <strong>“SEU PROBLEMA, MEU PROBLEMA”.</strong>
                                        <br />
                                        Esta é a filosofia do Detetive Marcos e de toda sua equipe de profissionais.
                                        <br />
                                        Soluções rápidas para problemas difíceis.
                                        <br />
                                        Conte conosco!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section-title">
                                    <h4>Data de Revisão: 01/04/2024</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PoliticaPrivacidade;