import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import PoliticaPrivacidade from "../components/PrivacyPolicy";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";

const PrivacyPolicy = () => {
  
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="Política de Privacidade" />
        <PoliticaPrivacidade />
        <Footer/>
      </main>
      <BtWhatsapp/>
      <Scrolltop />
    </>
  );
}

export default PrivacyPolicy;
