import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Counter from "../components/Counter";
import About2 from "../components/About2";
import Header from "../components/Header";
import { useParams } from "react-router-dom";
import Unidades from "../context/Unidades"
import BtWhatsapp from "../components/ButtonWhatsapp";

const About = () => {
  
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="Sobre" />
        <About2 />
        <Footer/>
      </main>
      <BtWhatsapp/>
      <Scrolltop />
    </>
  );
};

export default About;
