import react from 'react'
import { Link, useParams } from "react-router-dom";

const Menu2 = () => {
  const {uf} = useParams()

  return (
    <ul className="menu">
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/rede_sociais"}`}>Especialista em Redes sociais</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/localizar_pessoas"}`}>Encontrar Pessoas</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/localizar_devedores"}`}>Intermediação com Devedores</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/localizar_golpista"}`}>Intermediação com Golpista</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/levantamento"}`}>Levantamento de informações</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/rastreamento"}`}>Bens desviados</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/pericia"}`}>Computadores e celulares</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/dossie"}`}>Dossiê completo pessoa/empresa</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/golpista_amoroso"}`}>Golpista amoroso</a>
      </li>
      <li>
        <a href={`${process.env.PUBLIC_URL + '/#' + uf + "/politica_privacidade"}`}>Política de Privacidade</a>
      </li>
    </ul>
  )

}

export default Menu2;