import React, { useState } from "react";
import bg1 from "../img/slider/slider-2.jpg";
import bg_mb from "../img/slider/slider_mobile1.jpg";
import bg_mb1 from "../img/slider/slider_mobile.jpg";
import bg2 from "../img/slider/slider.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { Link, useParams } from "react-router-dom";
import Unidades from "../context/Unidades";

const Mainslider = () => {

  const {uf} = useParams()

  let contact = []
  if (uf){
    contact = Unidades.renderRegiao(uf)
  } 

  const options = {
    loop: true,
    autoplay: true,
    autoplayTimeout: 9000,
    slideSpeed: 2000,
    paginationSpeed: 2000,
    dots: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  if (window.innerWidth <= 800) {
    return (
      <>
        <div id="marcosslider-area" className="marcosslider-area slider-area-bg">
          <div className="marcosmain-slider">
            <OwlCarousel className="owl-theme owl-carousel" {...options}>
              <div className="marcossingle-slider">
                <div className="slid-bg-1" style={
                  { background: `url('${bg_mb}')` }
                } />
                <div className="marcossingle-table">
                  <div className="marcossingle-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-8 col-lg-10 col-md-12">
                          <div className="marcosslider-area-content">
                            <h4> Detetive Marcos</h4>
                            {uf.toUpperCase() === "MARCOS"
                            ? <>
                                <h2> Atendemos onde você estiver </h2>
                                <p>
                                  Em qualquer lugar do mundo!
                                </p>
                              </>
                            : <>
                                <h2> Solução de seu caso <br /> em <b>24 a 48</b> horas. </h2>
                                <div className="slider-area-btn">
                                  <div className="btn btn-type-2"
                                    onClick={()=>{window.open(`https://${window.screen.width < 500 ? 'api' : 'web'}.whatsapp.com/send/?phone=${contact[0].contato}`,'_blank')}}
                                    >
                                    Resolva seu problema <strong>AGORA</strong>
                                    <br />
                                    <br />
                                    <u>Clique Aqui</u>
                                  </div>
                                </div>
                              </>
                            } 
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="marcossingle-slider">
                <div
                  className="slid-bg-1"
                  style={{ background: `url('${bg_mb1}')` }}
                ></div>
                <div className="marcossingle-table">
                  <div className="marcossingle-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12">
                          <div className="marcosslider-area-content">
                            <h4>Casos Internacionais</h4>
                            {uf.toUpperCase() === "MARCOS"
                            ? <>
                                <h2> Especialista <b>Virtual!</b></h2>
                                <p>
                                  Atendimento imediato e discreto
                                </p>
                              </>
                            : <>
                                <h4>Atendimento</h4>
                                <h2>
                                  Atendimento <br /> sempre <b>discreto</b>
                                </h2>
                                <div className="slider-area-btn">
                                  <div className="btn btn-type-2"
                                    onClick={()=>{window.open(`https://${window.screen.width < 500 ? 'api' : 'web'}.whatsapp.com/send/?phone=${contact[0].contato}`,'_blank')}}
                                    >
                                    <strong>Obtenha a informação</strong>
                                    <br />
                                    que você precisa                                    
                                    <br />
                                    <u>Clique Aqui</u>
                                  </div>
                                </div>
                            </>                            
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="marcossingle-slider">
                <div
                  className="slid-bg-1"
                  style={{ background: `url('${bg_mb1}')` }}
                ></div>
                <div className="marcossingle-table">
                  <div className="marcossingle-tablecell">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-8 col-lg-8 col-md-12">
                          <div className="marcosslider-area-content">
                            <h4>Excelência em Investigação</h4>
                            {uf.toUpperCase() === "MARCOS"
                            ? <>
                                <h2> Atendimento Internacional </h2>
                                <p>
                                    Não importa onde está!
                                    <br />
                                    Nós atendemos você!
                                </p>
                              </>
                            : <>
                                <h4>Atendimento</h4>
                                <h2>
                                  Atendimento em <br /> todo <b>Brasil</b> e Exterior
                                </h2>
                                <div className="slider-area-btn">
                                  <div className="btn btn-type-2"
                                    onClick={()=>{window.open(`https://${window.screen.width < 500 ? 'api' : 'web'}.whatsapp.com/send/?phone=${contact[0].contato}`,'_blank')}}
                                    >
                                    Precisa de <strong>RESPOSTAS?</strong>
                                    <br />
                                    <br />
                                    <u>Clique Aqui</u>
                                  </div>
                                </div>

                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </>
    )}

  return (
    <>
      <div id="marcosslider-area" className="marcosslider-area slider-area-bg">
        <div className="marcosmain-slider">
          <OwlCarousel className="owl-theme owl-carousel" {...options}>
            <div className="marcossingle-slider">
              <div className="slid-bg-1" style={
                { background: `url('${bg1}')` }
              } />
              <div className="marcossingle-table">
                <div className="marcossingle-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-10 col-md-12">
                        <div className="marcosslider-area-content">
                            <h4>Detetive Marcos</h4>
                            {uf.toUpperCase() === "MARCOS"
                            ? <>
                                <h2> Atendemos onde você estiver </h2>
                                <p>
                                  Em qualquer lugar do mundo!
                                </p>
                              </>
                            : <>
                              <h2> Solução de seu caso<br /> em <b>24 a 48</b> horas. </h2>
                            </>
                          }
                          <div className="slider-area-btn">
                            <div className="btn btn-type-2"
                              onClick={()=>{window.open(`https://${window.screen.width < 500 ? 'api' : 'web'}.whatsapp.com/send/?phone=${contact[0].contato}`,'_blank')}}
                              >
                              Resolva seu problema <strong>AGORA</strong>
                              <br />
                              <br />
                              <u>Clique Aqui</u>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="marcossingle-slider">
              <div
                className="slid-bg-1"
                style={{ background: `url('${bg2}')` }}
              ></div>
              <div className="marcossingle-table">
                <div className="marcossingle-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-12">
                        <div className="marcosslider-area-content">
                            <h4>Casos Internacionais</h4>
                            {uf.toUpperCase() === "MARCOS"
                            ? <>
                                <h2> Especialista <b>Virtual!</b></h2>
                                <p>
                                  Atendimento imediato e discreto
                                </p>
                              </>
                            : <>
                                <h4>Atendimento</h4>
                                <h2>
                                  Atendimento <br /> sempre <b>discreto</b>
                                </h2>
                              </>
                          }
                          <div className="slider-area-btn">
                            <div className="btn btn-type-2"
                              onClick={()=>{window.open(`https://${window.screen.width < 500 ? 'api' : 'web'}.whatsapp.com/send/?phone=${contact[0].contato}`,'_blank')}}
                              >
                              <strong>Obtenha a informação</strong> que você precisa
                              <br />
                              <br />
                              <u>Clique Aqui</u>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="marcossingle-slider">
              <div
                className="slid-bg-1"
                style={{ background: `url('${bg2}')` }}
              ></div>
              <div className="marcossingle-table">
                <div className="marcossingle-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-12">
                        <div className="marcosslider-area-content">
                          <h4>Excelência em Investigação</h4>
                          {uf.toUpperCase() === "MARCOS"
                              ? <>
                                  <h2> Atendimento Internacional </h2>                              
                                  <p>
                                    Não importa onde está!
                                    <br />
                                    Nós atendemos você!
                                  </p>
                                </>
                              : <>
                                  <h4>Atendimento</h4>
                                  <h2>
                                      Atendimento em <br /> todo <b>Brasil</b> e <b>Exterior</b>
                                  </h2>
                                </>
                          }
                          <div className="slider-area-btn">
                            <div className="btn btn-type-2"
                              onClick={()=>{window.open(`https://${window.screen.width < 500 ? 'api' : 'web'}.whatsapp.com/send/?phone=${contact[0].contato}`,'_blank')}}
                              >
                              Precisa de <strong>RESPOSTAS?</strong>
                              <br />
                              <br />
                              <u>Clique Aqui</u>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default Mainslider;
