import React from "react";
import MenuBody from "./MenuBody";

const Investigation = () => {
  return (
    <>
      <div
        id="marcosteam-area"
        className="marcosteam-area mt-100 mb-70 wow fadeInUp"
      >

        <div className="container ">
          <div className="row">
            <MenuBody />

            <div className="col-xl-8 col-lg-3 col-md-6 col-sm-12 ">
              <div className="about-content">
                <p>
                  <h2>Detetive Virtual</h2>
                  O trabalho de um Detetive Virtual consiste em detectar um fato, ou investigar crimes e delitos cometidos através da rede (internet).
                  <br />
                  <br />
                  Agora, o Detetive Marcos conta com uma equipe especialista em redes sociais, 
                  com profissionais altamente capacitados e treinados, 
                  em investigar todas as plataformas digitais existentes (dentro de todos os preceitos legais). 
                  Nesse contexto, a união do nosso conhecimento prévio associado à expertise dos nossos 
                  profissionais especialistas no assunto, fez com o que nossa empresa se tornasse a pioneira nesse 
                  ramo específico de investigação.
                  <br />
                  <br />
                  Com o conhecimento de nossa equipe aliado à tecnologia que possuímos, informações tidas como absolutamente ocultas podem acabar sendo desvendadas. Além desses meios citados, temos outros métodos de enorme eficiência!
                  <br />
                  <br />
                  O nosso trabalho é desenvolvido mediante a necessidade de cada cliente. Ao término de cada serviço são entregues relatório com conclusão, filmagens, fotografias, entre outros.
                  O Acompanhamento e a execução do trabalho proposto é feita sob a supervisão direta do Detetive Marcos, para um melhor resultado, sempre prezando pelo contato direto com o cliente.
                  <br />
                  <br />
                  <h2>Nossa Equipe</h2>
                  A empresa do Marcos detetive é formada por profissionais qualificados e treinados para os diferentes tipos de trabalhos, oferecendo qualidade total, satisfação, competência, responsabilidade e segurança nos serviços desempenhados por toda equipe. Estando preparados para instruir o cliente em qualquer tipo de problema.
                  <br />
                  <br />
                  Nossos detetives são devidamente registrados e, portanto, exercem legalmente suas atividades.
                  <br />
                  <br />
                  <h2>Tecnologia</h2>
                  {/* Trabalhamos com o que há de melhor e mais moderno na área da tecnologia e da informação. Contamos hoje com auxilio de micro câmera, gravador de áudio, monitoramento via GPS, varredura telefônica, varredura de ambiente, melhores softwares do mercado, além de diversas outras tecnologias que permitem a localização de pessoas e bens, o monitoramento de computadores e celulares, entre outros serviços.
                  <br />
                  <br /> */}
                  Não importa em qual o seu problema, a verdade será descoberta. Somos os melhores Detetives para Investigações Online
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Investigation;
