import React from 'react';
import { useParams } from 'react-router-dom';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Unidades from "../context/Unidades"

const Menu3 = () => {
  const [whatsapp, setWhatsapp] = React.useState("")  
  const {uf} = useParams()

  React.useEffect(() => {
    const whats = Unidades.renderRegiao(uf).filter(value => value.principal === true)
    setWhatsapp(whats[0].contato.substring(1))
  }, [])

  const cards = [
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/rede_sociais"}`,
      title : 'Especialista em Redes sociais',
      icon  : 'fa-users',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/localizar_pessoas"}`,
      title : 'Encontrar Pessoas',
      icon  : 'fa-search',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/localizar_devedores"}`,
      title : 'Intermediação com Devedores',
      icon  : 'fa-hand-holding-usd',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/localizar_golpista"}`,
      title : 'Intermediação com Golpista',
      icon  : 'fa-exclamation-triangle',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/rastreamento"}`,
      title : 'Bens desviados',
      icon  : 'fa-lock',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/pericia"}`,
      title : 'Computadores e celulares',
      icon  : 'fa-laptop',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/dossie"}`,
      title : 'Dossiê Completo Pessoa/Empresa',
      icon  : 'fa-folder',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
    {
      url   : `${process.env.PUBLIC_URL + '/#' + uf + "/golpista_amoroso"}`,
      title : 'Golpista Amoroso',
      icon  : 'fa-heart-broken',
      startConversation : 'Olá, gostaria de ter informações sobre'
    },
  ]
  return (
    <div className=''>
      <div className="row align-items-stretch justify-content-around">
        {
          cards.map((card,index)=>{
            let formatText = ""
            let formatBackground = ""
            let formatIcon = ""

            console.log(card)

            if (index % 2 == 0){
              formatText        = "text-danger"
              formatBackground  = "text-danger"
              formatIcon        = "text-danger"
            } else {
              formatText        = "text-white"
              formatBackground  = "bg-danger"
              formatIcon        = "text-white"
            }

            return (
              <div className="col-12 col-md-3 p-2">
                <div className={`card text-center h-100 btn-card ${formatBackground}`}>

                    <div className="card-body">
                      <a href={card.url} data-toggle="tooltip" data-placement="bottom" title="Clique para saber mais">
                        <i className={`fas ${card.icon + " " + formatIcon} fa-4x `}></i>
                        <h5 className={`card-title ${formatText}`}>{card.title}</h5>
                      </a>
                      <a className="d-none d-mb-block d-xl-block p-10 text-success fw-bold" 
                        href={`https://web.whatsapp.com/send/?phone=${whatsapp}&text=Olá, gostaria de ter informações sobre *${card.title}*`} target="_blank">
                          <WhatsAppIcon className="icon" />
                          <span>Fale conosco</span>
                      </a>
                      <a className="d-block d-mb-none d-xl-none p-10 text-success fw-bold" 
                        href={`https://api.whatsapp.com/send/?phone=${whatsapp}&text=Olá, gostaria de ter informações sobre *${card.title}*`} target="_blank">
                          <WhatsAppIcon className="icon" />
                          <span>Fale conosco</span>
                      </a>

                    </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )

}

export default Menu3;