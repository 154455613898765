import React from "react";
import Image from "../img/whatsapp/WhatsApp.png";
import RedImage from "../img/whatsapp/WhatsApp-Red.png";

export default function WhatsAppButton({contato}) {
    return (
        <>
            <div className="whats-div">
                <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send/?phone=${contato}`} target="_blank" className="whats-btn">
                    Fale conosco AGORA pelo WhatsApp
                    <img src={Image} className="whats-icon" />
                </a>
            </div>

            {/* <div className="whats-div">
                <a href={`https://${window.screen.availWidth > 700 ? 'web' : 'api'}.whatsapp.com/send/?phone=${contato}`} target="_blank" className="whats-btn-2">
                    Fale conosco AGORA pelo WhatsApp
                    <img src={RedImage} className="whats-icon" />
                </a>
            </div> */}
        </>
    )
}