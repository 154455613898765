import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

const TextArea = ({ name, label, ...rest }) => {
  const inputRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  return (
    <>
      <textarea
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        className={error ? 'has-error form-control' : 'form-control'}
        cols="30"
        rows="8"
        {...rest}
      />

      {error && <span className="NameError">{error}</span>}
    </>
  )
}
export default TextArea;