import React from "react";
import Menu2 from "./Menu2";
import Menu3 from "./Menu3";
const MenuBody = () => {
  return (
    <>
      <div
        id="marcosservices-area"
        className="marcosservices-area"
      >
        <div className="container">
          <div className="menu-investicaco cl-12 wow fadeInLeft shadow rounded " >
            <div className="footer-single-wedget">
                <div className="widget_nav_menu">
                  <h3>Nossos Serviços</h3>
                  {/* <Menu2 /> */}
                  <Menu3 />
                </div>
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default MenuBody;
