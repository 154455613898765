import React from 'react'
import { useHistory } from 'react-router';
import logo from '../img/logo-3.png'

const Modal = ({ onClose = () => { }, children }) => {
  const history = useHistory();

  const handleOutsideClick = (event) => {
    if (event.target.id === "modal") onClose()
  }

  function handleSelect(event){
    history.push(`${'/' + event.target.value}`)
  }

  return (
    <div id="modal" onClick={handleOutsideClick}>
      <img src={logo}></img>


      <div className="appnox-contact-form">
        <select className="form-control" onChange={(event) => {handleSelect(event)}}>
          <option value="0">Escolha em qual estado você esta</option>
          <option value='AC'>Acre</option>
          <option value='AL'>Alagoas</option>
          <option value='AM'>Amazonas</option>
          <option value='AP'>Amapá</option>
          <option value='BA'>Bahia</option>
          <option value='CE'>Ceará</option>
          <option value='DF'>Distrito Federal</option>
          <option value='ES'>Espírito Santo</option>
          <option value='GO'>Goiás</option>
          <option value='MA'>Maranhão</option>
          <option value='MG'>Minas Gerais</option>
          <option value='MS'>Mato Grosso do Sul</option>
          <option value='PR'>Paraná</option>
          <option value='RS'>Rio Grande do Sul</option>
          <option value='SC'>Santa Catarina</option>
          <option value='RJ'>Rio de Janeiro</option>
          <option value='RO'>Rondônia</option>
          <option value='PA'>Pará</option>
          <option value='PB'>Paraíba</option>
          <option value='PE'>Pernambuco</option>
          <option value='PI'>Piauí</option>
          <option value='RR'>Roraima</option>
          <option value='MT'>Mato Grosso</option>
          <option value='TO'>Tocantins</option>
          <option value='SE'>Sergipe</option>
          <option value='RN'>Rio Grande do Norte</option>
          <option value='SP'>São Paulo</option>
          <option value='MARCOS'>Exterior</option>
        </select>

        
      </div>
    </div>
  )
}

export default Modal;