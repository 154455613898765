import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Investigation from "../components/Investigation";
import Header from "../components/Header";
import BtWhatsapp from "../components/ButtonWhatsapp";

const Team = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="Online" />
        <Investigation />
        <Footer />
      </main>
      <BtWhatsapp />
      <Scrolltop />
    </>
  );
};

export default Team;
